import axios from 'axios'

const host = 'https://sandbox-api-legacy.teamculture.com.br/v1'

export async function refreshToken(token, personSecret) {
  const { data } = await axios({
    method: 'post',
    url: `${host}/auth/person-refresh-token`,
    data: { personSecret },
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  return data.token
}
